import React from 'react';
import LivePlayer from 'html5-player';
import HistoryPlayer from 'html5-player/libs/history';
import moment from 'moment';
import VideoEvent from './component/VideoEvent';
import PropTypes from 'prop-types';
import NoPlayerData from './component/NoPlayerData';
import {
  TopContrallerBar,
  BottomContrallerBar
} from './component/ContrallerBar';

import HistoryTimeChoise from './component/HistoryTimeChoise';
import CameraControl from './component/CameraControl';
import RectSearchModal from './component/RectSearchModal';
import { Modal, message } from 'antd';
import { cloneDeep } from 'lodash';

import 'html5-player/libs/assets/css/style.css';
import './style/index.scss';

const DomMarkRepeat = Loader.loadBusinessComponent('WaterMakeDom')

const MAX_ERROR_NUM = 5;
const RE_CONNECT_TIME = 2 * 1000;

const fileDataMock = {"isLoop":false,"cid":"753383109","deviceName":"智诺模拟器1012","deviceStatus":"1","deviceType":"100604","id":"72057600753383109","latitude":"30.492975000000000","longitude":"114.410678000000000","lygroupId":"5c10f18155422cc7b85d85ef","manufacturerDeviceId":"753383109","manufacturerDeviceType":"103401","organizationIds":["100101001966"],"pathId":[],"placeId":"1200000000000000000","sn":"test_5123456702","boxNum":"","hasFrontEnd":"1","isFrontEnd":true,"storageLimitInfo":{"dataList":[{"from":"1571038423","to":"1571124823"}],"startTime":1571038423000,"endTime":1571124823000},"isLiving":false,

"historyList":{
  "beginDate":"2019-10-14 15:33:43",
  "duration":86400,
  "fragments":[
    {
      "begin":0,
      "end":86400,
      "file":"http://192.168.101.43/v2/753383109/localrecord.flv?client_token=753383109_65280_1572334424_26c7b0b6c6014bfbce93f0276fa4987c"
    }
  ]}
}


/**
 * 关闭部分默认配置，自定义实现
 */
const defaultControls = {
  playPause: false,
  volume: false,
  speed: false
};

const LoadingMessageComponent = props => {
  if(props.frontEndVideo){
    return <span> 重新加载中... </span>;
  }
  return <span>加载超时，第{props.count}次重连中...</span>;
};

@Decorator.shouldComponentUpdate
class VideoPlayer extends React.Component {
  static childContextTypes = {
    getPlayContainer: PropTypes.func,
    videoDom: PropTypes.object,
    player: PropTypes.object,
    fileData: PropTypes.object,
    playStatus: PropTypes.string,
    setPlayStatus: PropTypes.func,
    isNativeVideo:PropTypes.bool,
    isLiving: PropTypes.bool,
    stretching: PropTypes.string,
    setStretching: PropTypes.func,
    screenToCanvas: PropTypes.func, // 播放器截图
    isLoop: PropTypes.bool
  };
  getChildContext() {
    return {
      getPlayContainer: () => this.playerContainerRef.current,
      videoDom: this.playerContainerRef.current,
      player: this.player,
      playStatus: this.state.playStatus,
      setPlayStatus: this.setPlayStatus,
      // fileData: fileDataMock,
      // isLiving: false,
      isLiving: this.props.isLiving !== false,
      fileData: this.props.fileData,
      isNativeVideo:this.props.isNativeVideo,
      stretching: this.state.stretching,
      setStretching: this.setStretching,
      screenToCanvas: this.screenToCanvas,
      isLoop: this.props.isLoop
    };
  }
  constructor(props) {
    super(props);
    this.playId = `lm-player-${Math.random()}`;
    this.player = null;
    this.playerContainerRef = React.createRef();
    this.rectRef = React.createRef();
    this.fileData = cloneDeep(props.fileData);
    this.timer = null;
    this.errorNum = 0;
    this.errorTimer = 0;
    this.rectSearchOptions = {}; // 框选搜图参数
    this.state = {
      playStatus: null,
      stretching: 'uniform',
      showHistoryTimeChoise: false,
      key: Math.random(),
      timeRange: null, // 时间控件默认时间段
      autoHideBar: false,
      isPtzControl: 0, // 是否是云台控制 - 不能通过deviceType判断
      deviceInfo: {},
    };
  }

  componentDidMount(){
    // 调用接口，拿到设备详细信息，用来判断是否为云台等相关逻辑
    const { fileData } = this.props;
    this.judgeIsPtzControl(fileData.id);
  }
  componentWillUnmount() {
    this.fileData = null;
    clearTimeout(this.timer);
    this.timer = null;
  }
  componentWillReceiveProps(nextProps) {
    // 判断云台处理
    const { fileData } = nextProps;
    if(fileData.id && fileData.id !== this.fileData.id ){
      this.judgeIsPtzControl(fileData.id)
    }

    if (!Utils.isEqual(this.fileData, nextProps.fileData)) {
      const { isLiving = true } = nextProps;
      const newFileData = nextProps.fileData || {};
      const oldFileData = this.fileData || {};
      if (isLiving) {
        if (oldFileData.file !== newFileData.file) {
          this.setState({ key: Math.random(), playStatus: null });
        } else {
          this.setState({ playStatus: null });
        }
      } else {
        if (oldFileData.historyList !== newFileData.historyList) {
          this.setState({ key: Math.random(), playStatus: null });
        } else {
          this.setState({ playStatus: null });
        }
      }
      this.fileData = cloneDeep(newFileData);
      console.warn('当前视频播对象：', this.fileData);
    }
  }

  judgeIsPtzControl = async id => {
    if(!id){
      return;
    }
    let result = await Service.device.queryDeviceInfo(id);
    const deviceInfo = result.data || {};
    let isPtzControl = 0;
    try {
      const { type, ptz_control, platform } = deviceInfo.extJson.cameraInfo;
      isPtzControl = type=="gb"? ptz_control : platform;
      if(deviceInfo.accessType.toLowerCase() === 'sdk' && platform === 1) {
        isPtzControl = 1;
      }
    } catch {}
    this.setState({
      isPtzControl,
      deviceInfo
    })
  }

  setStretching = type => {
    this.setState({ stretching: type ? type : 'uniform' });
  };
  setPlayStatus = status => {
    const { fileData } = this.props;
    this.setState({ playStatus: status });
    /**如果视频处于播放状态, 而且视频能够获取流, 通知后台修改设备状态 */
    if(status === 'play' && fileData.deviceStatus == '0'){
      const deviceInfo = BaseStore.device.getDeviceByIdOrCid(fileData.cid);
      Service.device.updateDeviceStatus(deviceInfo.id, true)
      BaseStore.device.updateDevice({
        deviceId: fileData.id,
        deviceStatus: '1',
        type: 2,
      })
      SocketEmitter.emit(SocketEmitter.eventName.deviceUpdate, {})
    }
  };
  videoCallback = player => {
    this.player = player;
    this.forceUpdate();
    this.player.on('ready', (type, data) => {
      this.player.play();
    })
  };

  // 播放器截屏
  screenToCanvas = () => {
    const player = this.player
    if (player.isError || player.loading || player.ended){
      return false
    }
    const video = this.playerContainerRef.current.querySelector('video');
    let canvas = document.createElement('canvas');
    // canvas.width = video.clientWidth;
    // canvas.height = video.clientHeight;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const base64 = canvas.toDataURL();
    setTimeout(() => {
      ctx = null;
      canvas.remove();
      canvas = null;
    }, 10);
    return base64;
  }

  // 处理框选搜图
  handleRectSearch = (isGoPage) => {
    const { rectSearchStatus } = this.props.fileData;
    const { method } = this.props;
    if(rectSearchStatus) {
      this.clearRectSearch();
      // 关闭框选弹窗
      method.handleRectSearch(!rectSearchStatus, isGoPage);
    } else {
      let base64 = this.screenToCanvas();
      if(!base64) {
        method.handleRectSearch(false);
        return message.info('当前状态无法框选');
      }
      this.startRectSearch(base64);
      method.handleRectSearch(!rectSearchStatus, isGoPage);
    }
  }
  // 开启框选搜图
  startRectSearch = (base64) => {
    const isLiving = this.props.isLiving !== false;
    const player = this.player;
    if(!isLiving && player.playing) {
      player.pause();
      this.setPlayStatus('pause');
    }
    const width = this.playerContainerRef.current.querySelector('video').clientWidth;
    const rectSearchOptions = {
      width,
      imagePath: base64,
    }
    this.rectSearchOptions = rectSearchOptions;
  }

  // 关闭框选搜图
  clearRectSearch = () => {
    this.rectSearchOptions = {};
  }
  
  openOrHideHistoryPopup = (flag, eventType, isFrontEnd) => {
    Utils.exitFullscreen();
    if (eventType === 'download') {
      const { isLiving, historyList } = this.props.fileData;
      if(!isLiving && historyList) {
        const { beginDate, duration } = historyList;
        const timeRange = {
          startTime: moment(beginDate)*1,
          endTime: duration*1000 + moment(beginDate)*1
        }
        this.setState({ timeRange });
      }
      this.setState({ showHistoryTimeChoise: !!flag, eventType });
    } else {
      const { method, fileData } = this.props;
      const isHistory = true;
      const options = {...fileData};
      options.isFrontEnd = isFrontEnd
      method.setPlayMethods(options, isHistory);
    }
  };
  handleSelectTime = options => {
    const { eventType } = this.state;
    const { method } = this.props;
    if (eventType === 'history') {
      method.setPlayMethods(options);
    } else if (eventType === 'download') {
      method.downloadVideo(options);
    }
  };
  mouseoverAction = e => {
    clearTimeout(this.timer);
    this.setState({ autoHideBar: false });
    this.timer = setTimeout(() => {
      this.setState({ autoHideBar: true });
    }, 5000);
  };
  mouseooutAction = () => {
    clearTimeout(this.timer);
    this.setState({ autoHideBar: true });
  };

  /**
   * @desc 前进后退
   * @param {number} 时间间隔
   */
  changeVideoTime = time => {
    const { isEventVideo, changeTotalVideoTime, fileData } = this.props
    const { videoGroupType } = fileData;
    let currentTime = this.player.currentTime
    if(isEventVideo){
      if(time === -5 && (videoGroupType === 1 || videoGroupType === 3)){
        return
      }
      if(time === 5 && (videoGroupType === 2 || videoGroupType === 3)){
        return
      }
      changeTotalVideoTime && changeTotalVideoTime(time, currentTime)
      return
    }
    let duration = this.player.duration;
    let percent = (currentTime + time) / duration;
    this.player.setSeeking(percent);
  }

  render() {
    const {
      stretching,
      showHistoryTimeChoise,
      key,
      timeRange,
      eventType,
      autoHideBar,
      isPtzControl, // 是否为云台
      deviceInfo, //设备详细信息
    } = this.state;

    let {
      fileData = {},
      method = {},
      event = {},
      options = {},
      isLiving = true,
      hasLiving = true,
      hasHistory = true,
      hasFrontEnd = false,
      hasDownload = true,
      hasScreenshot=true,
      hideBar = false,
      isNativeVideo = false,
      customTopBar,
      isNormal = false,
      customBottmLeftBar,
      customBottmRightBar,
      isEventVideo,
      ...props
    } = this.props;

    let { file, historyList, rectSearchStatus, defaultCurrentTime, isFrontEnd } = fileData;

    const isEmpty = !file && !historyList;
    let Player = isLiving ? LivePlayer : HistoryPlayer;
  
    let playUrlOptions = { file, historyList };
    let retryTimes = 5;
    let timeout = 1000 * 15;
    let localization = { timeout: '视频加载超时' };
    let frontEndVideo = false;
    if(isFrontEnd && !isLiving) {
      frontEndVideo = true;
      retryTimes = 0;
      localization = { timeout: '视频加载失败' };
    }
    if(isNormal){
      Player = LivePlayer
    }else{
      if(!isLiving && historyList.fragments[0].file === 'error') {
        retryTimes = 0;
        localization = {
          timeout: '未查询到视频'
        }
      }
      isLiving ? delete playUrlOptions.historyList : delete playUrlOptions.file;
    }
    
    return (
      <div
        id={this.playId}
        className={`lm-player ${autoHideBar ? 'lm-player-hide-bar' : ''} ${
          props.className ? props.className : ''
        }`}
        draggable={false}
        ref={this.playerContainerRef}
        onMouseOver={this.mouseoverAction}
        onMouseMove={this.mouseoverAction}
        onMouseOut={this.mouseooutAction}
      >
        {isEmpty ? (
          <React.Fragment>
            {this.props.children &&
              React.cloneElement(this.props.children, { playInstance: this })}
            <NoPlayerData />
          </React.Fragment>
        ) : (
          <Player
            {...playUrlOptions}
            autoplay={props.autoplay !== false}
            // isLiving={isNativeVideo ? false : isLiving}
            isLiving={isLiving}
            onDoubleClick={() => {}}
            videoCallback={this.videoCallback}
            controls={defaultControls}
            contextMenu={[]}
            loop={false}
            stretching={stretching}
            livingMaxBuffer={3} // 直播缓存时间
            timeout={timeout} // 超时重载
            retryTimes={retryTimes} // 重载次数
            localization={localization}
            defaultCurrentTime={defaultCurrentTime}
            LoadingMessageComponent={<LoadingMessageComponent frontEndVideo={frontEndVideo}/>}
            key={key}
          >
            <div className="popup-set-volume-popup" />
            <div className="popup-set-speed-popup" />

            <DomMarkRepeat className="player-marke" />

            <RectSearchModal 
              width='60%'
              cancelRectSearch={this.handleRectSearch}
              title='框选搜图'
              visible={rectSearchStatus}
              rectSearchOptions={this.rectSearchOptions}
            />

            {!hideBar && (
              <React.Fragment>
                <TopContrallerBar
                  customTopBar={customTopBar}
                  title={fileData.deviceName}
                  closeVideo={method.closeVideo}
                />
                <BottomContrallerBar
                  hasHistory={hasHistory}
                  hasFrontEnd={hasFrontEnd}
                  hasLiving={hasLiving}
                  hasDownload={hasDownload}
                  hasScreenshot={hasScreenshot}
                  hasRectSearch={!!method.handleRectSearch}
                  customBottmLeftBar={customBottmLeftBar}
                  customBottmRightBar={customBottmRightBar}
                  setPlayMethods={method.setPlayMethods}
                  openHistoryPopup={this.openOrHideHistoryPopup}
                  setRectSearchModal={this.handleRectSearch}
                  rectSearchStatus={rectSearchStatus}
                  changeVideoTime={this.changeVideoTime}
                  isEventVideo={isEventVideo}
                  onFrontEndSeek={method.onFrontEndSeek}
                />
              </React.Fragment>
            )}
            {showHistoryTimeChoise && (
               <Modal visible={true} footer={false} width={320} title={false} closable={false} className="play-choise-time">
                <HistoryTimeChoise
                  timeRange={timeRange}
                  eventType={eventType}
                  close={() => this.openOrHideHistoryPopup(false, eventType)}
                  onSelectTime={this.handleSelectTime}
                />
              </Modal>
            )}
            {isPtzControl === 1 && isLiving === true && (
              <CameraControl deviceInfo={deviceInfo} />
            )}
            <VideoEvent {...event} isEmpty={isEmpty} />
            {this.props.children &&
              React.cloneElement(this.props.children, { playInstance: this })}
          </Player>
        )}
      </div>
    );
  }
}
export default VideoPlayer