import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import CustomSlider from "./CustomSlider";
import "../style/camera-control.less";

const IconFont = Loader.loadBaseComponent("IconFont");

const layerPos = [
  "up",
  "rightup",
  "right",
  "rightdown",
  "down",
  "leftdown",
  "left",
  "leftup"
];
const zoomType = ["zoomin", "zoomout"];
const focusType = ["focusin", "focusout"];
export default class CameraControl extends Component {
  static contextTypes = {
    fileData: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.isGB = false;
    // 国标摄像机
    // 控制速度0-3
    // 不能控制焦距，只能步骤控制（点控）

    // SDK
    // 控制速度0-8
    // 可以控制云台转动和焦距，持续控制

    // 非国标
    // 控制速度0-255
    // 上下控制较明显、左右不明显
    // 不能控制焦距，只能步骤控制（点控）

    this.state = {
      speed: this.isGB ? 2 : 20,
      totalSpeed: this.isGB ? 3 : 255,
      show: false
    };
  }

  componentDidMount() {
    const { deviceInfo } = this.props;
    let speed, totalSpeed;
    try {
      if(deviceInfo.extJson.cameraInfo.type.toLowerCase() === "gb"){
        this.isGB = true;
        speed = 2;
        totalSpeed = 3;
      }
      if(deviceInfo.accessType.toLowerCase() === 'sdk'){
        this.isGB = true
        speed = 3;
        totalSpeed = 8;
      }
    } catch (e) {
      console.warn("fileData.extJson.cameraInfo", deviceInfo);
    }
    this.setState({
      speed: speed ? speed : 20,
      totalSpeed: totalSpeed ? totalSpeed : 255,
      show: false
    });

    // const { fileData } = this.context;
    // Service.device.queryDeviceInfoByCid(fileData.cid).then(res => {
    //   try {
    //     this.isGB = res.data.extJson.cameraInfo.type.toLowerCase() === "gb";
    //   } catch (e) {
    //     console.warn("fileData.extJson.cameraInfo", res.result);
    //   }
    //   this.setState({
    //     speed: this.isGB ? 2 : 20,
    //     show: false
    //   });
    // });
  }

  bindEvents = () => {
    const { deviceInfo } = this.props;
    const isSDK = (typeof deviceInfo.accessType === 'string') && deviceInfo.accessType.toLowerCase() === 'sdk';
    layerPos.map(v => {
      this[v].addEventListener("mousedown", this.controlHandle);
      this[v].addEventListener("mouseup", this.cancleControl);
    });
    zoomType.map(v => {
      this[v].addEventListener("mousedown", this.zoomAction);
      this[v].addEventListener("mouseup", this.cancleControl);
    });
    isSDK && focusType.map(v => {
      this[v].addEventListener("mousedown", this.focusAction);
      this[v].addEventListener("mouseup", this.cancleFocus);
    });
  };

  removeEvents = () => {
    const { deviceInfo } = this.props;
    const isSDK = (typeof deviceInfo.accessType === 'string') && deviceInfo.accessType.toLowerCase() === 'sdk';
    layerPos.map(v => {
      this[v].removeEventListener("mousedown", this.controlHandle);
      this[v].removeEventListener("mouseup", this.cancleControl);
    });
    zoomType.map(v => {
      this[v].removeEventListener("mousedown", this.zoomAction);
      this[v].removeEventListener("mouseup", this.cancleControl);
    });
    isSDK && focusType.map(v => {
      this[v].addEventListener("mousedown", this.focusAction);
      this[v].addEventListener("mouseup", this.cancleFocus);
    });
  };

  setSpeed = speed => {
    this.setState({ speed });
  };

  // 调焦
  zoomAction = e => {
    // 国标相机才能调焦
    this.controlHandle(e);
  };

  controlHandle = (e, isStop = false) => {
    const direction = e.target.dataset.type;
    const { fileData } = this.context;
    const { speed } = this.state;
    let cmd, control;
    if (this.isGB) {
      cmd = isStop ? "stop" : "move";
      control = {
        direction,
        speed
      };
    } else {
      cmd = "direction";
      control = {
        direction,
        delay: 100,
        step: speed
      };
    }
    Service.video.unifyControlPTZ({
      cid: fileData.cid,
      cmd,
      control
    });
  };

  cancleControl = e => {
    if (this.isGB) {
      setTimeout(() => {
        this.controlHandle(e, true);
      }, 100);
    }
  };

  // 手动聚焦
  focusAction = (e, isStop = false) => {
    const direction = e.target.dataset.type;
    const { fileData } = this.context;
    Service.video.controlFocus({
      cid: fileData.cid,
      cmd: isStop ? "stop" : "manual",
      control: {
        operation: direction,
        speed: 4
      }
    });
  };

  // 取消聚焦
  cancleFocus = e => {
    setTimeout(() => {
      this.focusAction(e, true);
    }, 100);
  };

  // 自动聚焦
  handleAutoFocus = () => {
    const { fileData } = this.context;
    Service.video.controlFocus({
      cid: fileData.cid,
      cmd: "auto"
    });
  };

  onDoubleClick = e => {
    Utils.stopPropagation(e);
  };

  setVisible = () => {
    const show = !this.state.show;
    if (show) {
      this.setState({ show }, this.bindEvents);
    } else {
      this.removeEvents();
      this.setState({ show });
    }
  };

  render() {
    let { show, totalSpeed, speed } = this.state;
    const { deviceInfo } = this.props;
    const isSDK = (typeof deviceInfo.accessType === 'string') && deviceInfo.accessType.toLowerCase() === 'sdk';
    return (
      <div className="camera-control-layer" onDoubleClick={this.onDoubleClick}>
        <span
          className={`ptz-btn ${show ? "open" : ""}`}
          onClick={this.setVisible}
        >
          <span className="ptz-status-icon" />
        </span>
        {show && (
          <div className="layout-content">
            <div className="camera-control-wrapper">
              <ul>
                {layerPos.map(item => (
                  <li key={item} className={`camera-control-${item}`}>
                    <span
                      className="control-item"
                      data-type={item}
                      ref={direction => (this[item] = direction)}
                    >
                      <span />
                    </span>
                  </li>
                ))}
              </ul>
              <div className="center-layer" />
            </div>
            {/* <div className={`box-zoom ${this.isGB ? "" : "disabled"}`}> */}
            <div className={`box-zoom`}>
              <span
                className="zoomin"
                data-type="zoomin"
                ref={zoomin => (this.zoomin = zoomin)}
              >
                <IconFont type="icon-S_Arrow_SolidUp" title="缩小" />
              </span>
              <span className='box-label'>变倍</span>
              <span
                className="zoomout"
                data-type="zoomout"
                ref={zoomout => (this.zoomout = zoomout)}
              >
                <IconFont type="icon-S_Arrow_SolidDown" title="放大" />
              </span>
            </div>
            {/* <div className={`box-focus ${this.isGB ? "" : "disabled"}`}> */}
            {isSDK && <div className={`box-focus`}>
              <span
                className="focusin"
                data-type="near"
                ref={focusin => (this.focusin = focusin)}
              >
                <IconFont type="icon-S_Arrow_SolidUp" title="焦距拉近" />
              </span>
              <span className='box-label'>聚焦</span>
              <span
                className="focusout"
                data-type="far"
                ref={focusout => (this.focusout = focusout)}
              >
                <IconFont type="icon-S_Arrow_SolidDown" title="焦距推远" />
              </span>
              <span
                className="focusauto"
                data-type="focusauto"
                onClick={this.handleAutoFocus}
                ref={focusauto => (this.focusauto = focusauto)}
              >
                <IconFont type="icon-S_Bar_LatLong" title="自动聚焦" />
              </span>
            </div>}
            <div className="box-speed">
              <span className="box-speed-icon" />
              <CustomSlider
                min={0}
                max={totalSpeed}
                defaultPercent={speed / totalSpeed}
                onChange={this.setSpeed}
              />
              {/* <Slider
                min={0}
                max={this.isGB ? 3 : 255}
                defaultValue={this.isGB ? 2 : 20}
                onChange={this.setSpeed}
              /> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
