import React from 'react';
import ReactDOM from 'react-dom';
import Slider from './Slider';
import TimeTip from './TimeTip';

class TimeLine extends React.Component {

  state = {
    percent: this.props.seekPercent || 0
  }

  componentDidMount() {
    this.timeSliderDOM = ReactDOM.findDOMNode(this.refs.slider);
  }

  onSliderChange = percent => {
    this.setState({ percent });
  };

  onMouseUp = (e, percent) => {
    // seek
    const { duration, beginDateTime, onFrontEndSeek } = this.props;
    const seekTime = parseInt((beginDateTime + percent * duration) * 1000)
    // 新的seek时间
    onFrontEndSeek && onFrontEndSeek(seekTime, percent);
  };

  computedStyle = (item, index, duration, length) => {
    let gap = item.end - item.begin;
    let width = gap / duration * 100 + '%';
    if (length === index + 1) {
      //这样才会覆盖整个slider
      width = (gap / duration + 0.1) * 100 + '%';
    }
    const left = item.begin / duration * 100 + '%';
    const style = { left, width };
    return style;
  }
  render() {
    let {
      fragments,
      beginDateTime,
      selection,
      isError,
      duration,
      seekPercent,
    } = this.props;
    const { percent } = this.state;
    const length = fragments.length;
    return (
      <Slider
        ref="slider"
        onChange={this.onSliderChange}
        percent={percent}
        padding="0 10px"
        className={`html5-player-custom-time-slider ${isError ? 'html5-player-hide': ''}
        `}
        // onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onlyShowCircleOnEnter
      >
        {selection && this.renderSelection()}
        <div
          className='history-fragments-container'
          style={{
            overflow: 'hidden',
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          {length &&
            fragments.map((v, k) => {
              const style = this.computedStyle(v, k, duration, length)
              return (
                <div
                  key={k}
                  className={v.file ? 'html5-player-hasvideo' : 'html5-player-broken'}
                  style={style}
                />
              );
            })}
        </div>
        <TimeTip
          duration={duration}
          beginDateTime={beginDateTime} 
          seekPercent={seekPercent}
        />
      </Slider>
    );
  }
}
export default TimeLine;