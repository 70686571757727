import React, { Component } from 'react';
import { Button } from 'antd';

const ModalComponent = Loader.loadBaseComponent('ModalComponent');
const IconFont = Loader.loadBaseComponent('IconFont');
const PictureTools = Loader.loadBusinessComponent('PictureTools');

class RectSearchModal extends Component {

  state = {
    rectStatus: false
  }

  componentWillUnmount() {
    this.pictureCanvas = null;
  }

  initPictureTools = (pictureCanvas) => {
    this.pictureCanvas = pictureCanvas;
  }

  setRectStatus = () => {
    const { rectStatus } = this.state;
    if(rectStatus) {
      this.pictureCanvas.cancelScreenshot();
    } else {
      this.pictureCanvas.startScreenshot();
    }
    this.setState({
      rectStatus: !rectStatus
    })
  }

  handleCancel = (isGoPage = false) => {
    const { cancelRectSearch } = this.props;
    this.pictureCanvas.cancelScreenshot();
    this.setState({
      rectStatus: false,
    })
    cancelRectSearch && cancelRectSearch(isGoPage)
  }

  beforeJumppage = () => {
    const isGoPage = true;
    this.handleCancel(isGoPage)
  }

  render() {
    const { className='', visible, title, rectSearchOptions, ...rest } = this.props;
    const { rectStatus } = this.state;
    return (
      <ModalComponent
        className={`rect-search-modal ${className}`}
        visible={visible} 
        title={title}
        otherModalFooter={
          <Button className='rect-btn' onClick={this.setRectStatus}>
            <IconFont type="icon-S_View_SearchBox" />
            {rectStatus ? '取消框选' : '框选搜图'}
          </Button>
        }
        onCancel={() => this.handleCancel()} 
        {...rest}
      >
        {visible && (
          <PictureTools 
            {...rectSearchOptions} 
            onInit={this.initPictureTools}
            beforeJumppage={this.beforeJumppage}
            allowDrag={false}
          />
        )}
      </ModalComponent>
    )
  }
}
export default RectSearchModal;