import React from 'react';
import _ from 'lodash';
import TimeLine from './TimeLine';
import './index.less';

class CustomTimeline extends React.Component {

  fragments = () => {
    const { historyList } = this.props;
    const fragments = historyList.fragments || [];
    return fragments;
  }
  duration = () => {
    const { historyList } = this.props;
    return (historyList && historyList.duration) || 0;
  }
  render() {
    const { historyList: { beginDate }, onFrontEndSeek, seekPercent } = this.props;
    return (
      <TimeLine
        onFrontEndSeek={onFrontEndSeek}
        seekPercent={seekPercent}
        fragments={this.fragments()}
        duration={this.duration()}
        beginDateTime={+new Date(beginDate.replace(/-/g, '/')) / 1000}
      />
    )
  }
}
export default CustomTimeline;