//外部依赖包
import React from 'react';
//内部依赖包
import Tooltip from './Tooltip';

export default class TimeTooltip extends React.Component {
  //这里的配置参考jw-player的api
  static propTypes = {};
  static displayName = 'TimeTooltip';
  state = {
    percent: this.props.seekPercent || 0.5,
  };
  onChange = percent => {
    this.setState({
      percent,
    });
  };
  renderContent() {
    let { duration, beginDateTime } = this.props;
    const { percent } = this.state;
    if (duration === 0) {
      return false;
    }
    const formatTime = beginDateTime && dateFormat(
      (beginDateTime + percent * duration) * 1000,
      'YYYY-MM-DD HH:mm:ss'
    );
    return (
      <div className="html5-player-time-tooltip-content">{formatTime}</div>
    );
  }
  render() {
    return (
      <Tooltip
        className="html5-player-time-tooltip"
        content={this.renderContent()}
        type="move"
        onChange={this.onChange}
        percent={this.state.percent}
        isAnimateActive={false}
      >
        <div className="html5-player-for-tooltip" />
      </Tooltip>
    );
  }
}

function dateFormat(time, fmt) {
  var date = new Date(time);
  var o = {
    'M+': date.getMonth() + 1, //月份
    'D+': date.getDate(), //日
    'H+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(Y+)/.test(fmt)){
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o){
    if (new RegExp('(' + k + ')').test(fmt)){
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
    }
  }
  return fmt;
}