(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("PropTypes"), require("antd"), require("ReactDOM"), require("moment"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define("Player", ["React", "PropTypes", "antd", "ReactDOM", "moment", "_"], factory);
	else if(typeof exports === 'object')
		exports["Player"] = factory(require("React"), require("PropTypes"), require("antd"), require("ReactDOM"), require("moment"), require("_"));
	else
		root["Player"] = factory(root["React"], root["PropTypes"], root["antd"], root["ReactDOM"], root["moment"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__23__) {
return 