export default {
  Error: 'error',
  Loading:'loading',
  ReConnect:'reConnect',
  Ready:'ready',
  Canplay:'canplay',
  Waiting:'waiting',
  Pause:'pause',
  Play:'play',
  Reload:'reload'
};
